/* eslint-disable @typescript-eslint/member-ordering */
import type { KhazanahBlogDataModel } from '../blog/types';
import type { PromoDetailDataModel } from '../promo/types';

interface BannerDataModel {
  bannerID: string
  description: string
  title: string
  src: string
}

export interface SolutionsDataModel {
  id: string
  src: string
  description: string
  title: string
}

export interface ProductsDataModel {
  id: string
  src: string
  description: string
  title: string
}

export interface TestimonialDataModel {
  testimonialId: string
  name: string
  company: string
  description: string
  src: string
  position: string
}

export interface LandingModel {
  solutions: SolutionsDataModel[]
  testimonial: TestimonialDataModel[]
  banner: BannerDataModel[]
  khazanah: KhazanahBlogDataModel[]
  promo: PromoDetailDataModel[]
  products: ProductsDataModel[]
  insight: KhazanahBlogDataModel[]
  promoCard: KhazanahBlogDataModel[]
}

export enum LandingActionType {
  GetSolutionsList = '⌘➝App➝Landing➝GetSolutionsList',
  GetTestimonialList = '⌘➝App➝Landing➝GetTestimonialList',
  GetBannerList = '⌘➝App➝Landing➝GetBannerList',
  PostKhazanahList = '⌘➝App➝Landing➝PostKhazanahList',
  PostPromoCarousel = '⌘➝App➝Landing➝PostPromoCarousel',
  GetProductsList = '⌘➝App➝Landing➝ GetProductsList',
  GetInsight = '⌘➝App➝Landing➝ GetInsight',
  GetPromoCard = '⌘➝App➝Landing➝ GetPromoCard'
}

export type LandingAction = {
  data: BannerDataModel[]
  type: LandingActionType.GetBannerList
} | {
  data: KhazanahBlogDataModel[]
  type: LandingActionType.GetInsight
} | {
  data: KhazanahBlogDataModel[]
  type: LandingActionType.GetPromoCard
} | {
  data: KhazanahBlogDataModel[]
  type: LandingActionType.PostKhazanahList
} | {
  data: ProductsDataModel[]
  type: LandingActionType.GetProductsList
} | {
  data: PromoDetailDataModel[]
  type: LandingActionType.PostPromoCarousel
} | {
  data: SolutionsDataModel[]
  type: LandingActionType.GetSolutionsList
} | {
  data: TestimonialDataModel[]
  type: LandingActionType.GetTestimonialList
};
